import React, {useState} from 'react'
import { Link } from "react-router-dom";
import '../style/navbar.css'
import { Player, Controls } from '@lottiefiles/react-lottie-player';


export let RouteHelper = (props) => {
    const [selected, isSelected] = useState(null)


    return (
        <div style={{position:'relative'}}>
            {props.Assistant &&
                <div className='d-flex backBtn' onClick={props.BackBtnOnClick} data-bs-target="#myCarousel" data-bs-slide="back">
                    <span className="material-icons" style={{color:'#5C6BC0',paddingLeft:10}}>arrow_back_ios</span>
                </div>
            }
           

            <div 
                className={`${props.Assistant? 'active' : ''} animate__animated animate__fadeInDown assistantCard`}
            >
              
                <div className='w-100 h-100'>
                    {/* <br/> */}
                
                    {/* <div 
                        className='row'
                        style={{
                            position:'fixed'
                        }}
                    >
                        <div className='col text-center'><h6>Routing Assistant</h6></div>
                    </div> */}
                    <h6>Routing Assistant</h6>

                    {!props.Assistant &&
                        <div className='w-100' style={{flexDirection:'row'}}>
                            <Player
                                autoplay
                                loop
                                src="https://lottie.host/d4bd41d1-5b58-44b5-8b2c-dc1fe3dd7f8b/EPFIevzCAo.json"
                                style={{ height: '100px', width: '100px' }}
                            >
                            
                            </Player>

                            {/* <h6>Need help routing somebody?</h6>

                            <div className='customBtn' onClick={props.onClick}>
                                <h6 className='mb-0'>Yes</h6>
                            </div> */}

                    
                        </div>
                    }
                
                    {props.Assistant &&
                        <>
                            <div id="myCarousel" class="carousel slide" style={{overflowY: 'auto'}}>
                                <br/>
                                <div class="carousel-inner" >
                                    <div class="carousel-item active">
                                        <div 
                                            className='row'
                                            style={{
                                                justifyContent:'center',
                                                alignItems:'center',
                                                margin:'auto',
                                                paddingTop:25
                                            }}
                                        >
                                            {/* <SelectBox Text='Sales / Technical Support / ESG' />
                                            <SelectBox Text='Parts / Products / Order Processing' />
                                            <SelectBox Text='Product Repairs' />
                                            <SelectBox Text='Division Offices' />
                                            <SelectBox Text='Corporate Departments / Emp Assistance' />
                                            <SelectBox Text='All Other Inquiries & Operator' /> */}
                                            {props.Carousel1}
                                        </div>
                                        
                                    </div>
                                    <div class="carousel-item">
                                        <div className='row'>
                                            {props.Carousel2}
                                        </div>
                                        
                                    </div>
                                    <div class="carousel-item">
                                        {props.Carousel3}
                                    </div>
                                </div>

                        
                            </div>
                            
                        
                        </>
                        

                        
                    }

                
                    
                    
                </div>
            </div>
        </div>
      
    )
}

