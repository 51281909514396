import React, {useState} from 'react'
import { Link } from "react-router-dom";
import '../style/navbar.css'





let Navbar = (props) => {
    let url = window?.location?.href?.split('/')[3] == '' ? null : window?.location?.href?.split('/')[3][0]?.toUpperCase() + window?.location?.href?.split('/')[3]?.substring(1)
    const [selected, isSelected] = useState(url)
    console.log(selected,window?.location?.href?.split('/')[3])
    let Menu = (props) => {
        
        let isActive = () => {
            if ((props.Text === 'Directory' && selected === null) || (selected === props.Text)) {
                console.log('activate ==> ',props.Text)
                return true
            }
            else return false
        }

        return(
            <Link to={props.to} className={isActive()? 'd-flex p-2 menu active':'d-flex p-2 menu'} onClick={() => {
                isSelected(props.Text)
            }}>
                <i className="material-icons menuIcons">{props.Icon}</i>
                <p className="text-center" style={{color:'whitesmoke'}}>{props.Text}</p>
            </Link>
        )
    }
    
    return (
        
        <div className={` ${props.Show? 'show':''} mynavbar navbar animate__animated animate__fadeInLeft`}>
            {/* <div className='w-100'>
                <img className='logo' src={require('../gfx/logo.png')}/>
            </div> */}
             
           
            <div className='w-100 h-100'>
               
                <Menu to='/' Icon='groups' Text='Directory' Active={true} />
                <Menu to='/search' Icon='search' Text='Search' />

                <Menu to='/apps' Icon='link' Text='Apps' />
            </div>

            <img 
                src='../../logoflame.png'
                style={{
                    position: 'absolute',
                    width: '50%',
                    bottom: 0,
                    right: 0
                }}  
            />

            {/* <img 
                src='../../logo.png'
                style={{
                    position: 'absolute',
                    width: '50%',
                    bottom: '19%',
                    left: '5%'
                }}  
            /> */}
        </div>
    )
}

export default Navbar