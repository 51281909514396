import React, {useState} from 'react'
import { Link } from "react-router-dom";
import '../style/navbar.css'
import { Player, Controls } from '@lottiefiles/react-lottie-player';




export let Pills = (props) => {
    const [selected, isSelected] = useState(null)

    let isActive = () => {
        if ((props.Active === props.Text)) {
            return true
        }
        else return false
    }
    
    return (
        <div 
            className={isActive()? 'mb-3 menuPill active' : 'mb-3 menuPill'}
            onClick={props.onClick}
        >
            <p className='mb-0'>{props.Text}</p>
        </div>
        
    )
}

export let PillsTopMenu = (props) => {
    const [selected, isSelected] = useState(null)

    let isActive = () => {
        if ((props.Active === props.Text)) {
            return true
        }
        else return false
    }
    
    return (
        <div 
            className={isActive()? 'mb-3 menuPillTop active ' : 'mb-3 menuPillTop'}
            onClick={props.onClick}
        >
            <p className='mb-0'>{props.Text}</p>
        </div>
        
    )
}

