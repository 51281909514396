import logo from './logo.svg';
import './App.css';

import React, {useMemo, useEffect, useState} from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";


import Navbar from './components/navbar.js';
import Directory from './routes/directory';
import Search from './routes/search';
import Apps from './routes/apps.js';

function App() {
  let url = window?.location?.href?.split('/')[3] == '' ? null : window?.location?.href?.split('/')[3][0]?.toUpperCase() + window?.location?.href?.split('/')[3]?.substring(1)
  const [extend, isExtended] = useState(false)
  const [selected, isSelected] = useState(url)
  // const navigate = useNavigate();

  console.log('selected from app ==> ',selected)

  return (
    <BrowserRouter>
      <div className="App d-flex">
        <div className='row mobilecontrol'>
          <Link className='mCover' to='/'>
            <span 
              className={`material-icons mobile-menu ${selected == null? 'active': ''}`}
              style={{color:'white'}}
              
              onClick={() => {
                isSelected(null)
              }}
              >groups</span>
          </Link>
          

          <Link className='mCover' to='/search'>
            <span 
              className={`material-icons mobile-menu ${selected == 'search'? 'active': ''}`}
              style={{color:'white'}}
              onClick={() => {
                isSelected('search')
              }}
              >search</span>
          </Link>

          <Link className='mCover' to='/apps'>
            <span 
              className={`material-icons mobile-menu ${selected == 'apps'? 'active': ''}`}
              style={{color:'white'}}
              onClick={() => {
                isSelected('apps')
              }}
              >link</span>
          </Link>
        </div>
        
        <Navbar Show={extend} />
       
        <Routes>
          <Route path="/" element={<Directory />} />
          
          <Route 
            path="search" 
            element={<Search />} 
            children
          />

          <Route 
            path="apps" 
            element={<Apps />} 
            children
          />

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
