import React, {useMemo, useEffect, useState, useRef} from 'react';
import { RouteHelper } from '../components/routehelper';
import { Pills, PillsTopMenu } from '../components/pills';

// remove carousel library
// window.addEventListener('scroll', console.log(window.scrollY))

let Apps = (props) => {
    const [search, isSearching] = useState(false)
    const [data, isData] = useState([])
    const [assistantData, isAssistantData] = useState([])
    const [searchData, isSearchData] = useState([])
    const [notif, isNotif] = useState('hide')
    const [showmenu, isShowMenu] = useState(false)
    const myElementRef = useRef(null);
    const [assistant, isAssistant] = useState(false);
    const [activepill,isActivePill] = useState('Recommended')
    const [lookfor,isLookingFor] = useState(0)
    const [profile,isProfile] = useState({
        FirstName: 'N/A',
        LastName: 'N/A',
        PhoneNumber: 'N/A',
        JobTitle: 'N/A',
        Location: 'N/A',
        Department: 'N/A',
        ReportsTo: 'N/A',
        Email:'N/A'
    })

    let AppCard = (props) => (
        <div className='appCard'>
            <div 
                className='d-flex'
                style={{
                    justifyContent:'start',
                    alignItems:'center',
                    marginBottom:8
                }}
                onClick={() => window.open(props.Link,'_blank')}
            >
                <img 
                    style={{
                        width:60,
                        border: '2px solid whitesmoke',
                        padding:7,
                        marginRight:'3%',
                        
                    }} 
                    src={props.Logo} />
                <p 
                    className='mb-0'
                    style={{
                        fontWeight:600
                    }}
                >
                    {props.Name}
                </p>
            </div>
            
            {/* <br/> */}
            <div className='d-flex'>
                <small style={{textAlign:'left',fontSize:12}}>{props.Desc}</small>
            </div>
            
        </div>
    )

    return (
        <div 
            className='w-100' 
            style={{overflow:'hidden',height:'100vh'}}
        >

            <br/><br/><br/>
            <div 
                id='test'
                ref={myElementRef}
                className='animate__animated animate__fadeInUp'
                style={{
                    width:'100%',
                    height:'100%',
                    margin:'auto',
                    backgroundColor:'#ffffffe0',
                    borderRadius:24,
                    boxShadow: '0px 4px 12px -37px rgba(0,0,0,0.1)',
                    padding:15,
                    overflowY:'auto',
                }}
            >
                <div className='d-flex' style={{marginTop:10,marginBottom:10}}>
                    <Pills 
                        Active={activepill} 
                        onClick={() => isActivePill('Recommended')}
                        Text='Recommended'
                    />

                    <Pills 
                        Active={activepill} 
                        onClick={() => isActivePill('Communication')}
                        Text='Communication'
                    />

                    <Pills 
                        Active={activepill} 
                        onClick={() => isActivePill('Productivity')}
                        Text='Productivity'
                    />
                </div>


                <div className='d-flex' style={{width:'100%',flexWrap:'wrap'}}>
                    {activepill == 'Recommended' &&
                        <>
                            <AppCard 
                                Name='Outlook'
                                Logo='https://banner2.cleanpng.com/20180418/cfq/kisspng-microsoft-outlook-outlook-com-outlook-mobile-email-outlook-5ad785fe545f10.8090671015240739823456.jpg'
                                Desc="Provides emails, contacts, calendaring, and task management."
                                Link='https://outlook.office.com/mail/'
                            />

                            <AppCard 
                                Name='Teams'
                                Logo='https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg/2203px-Microsoft_Office_Teams_%282018%E2%80%93present%29.svg.png'
                                Desc="Messaging system with communication, meetings, file and app sharing."
                                Link='https://teams.microsoft.com/'
                            />

                            <AppCard 
                                Name='Word'
                                Logo='https://logos-world.net/wp-content/uploads/2020/03/Microsoft-Word-Logo.png'
                                Desc="Create and edit documents containing text and images."
                                Link='https://www.office.com/launch/word'
                            />

                            <AppCard 
                                Name='Sharepoint'
                                Logo='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Microsoft_Office_SharePoint_%282019%E2%80%93present%29.svg/2097px-Microsoft_Office_SharePoint_%282019%E2%80%93present%29.svg.png'
                                Desc="Share and manage content, knowledge, and applications to empower teamwork."
                                Link='https://www.microsoft.com/en/microsoft-365/sharepoint/collaboration'
                            />


                            <AppCard 
                                Name='ADP'
                                Logo='https://1000logos.net/wp-content/uploads/2021/04/ADP-logo.png'
                                Desc="Review pay statements, benefits, company policies and request PTO."
                                Link='https://adptotalsource.adp.com/'
                            />

                            <AppCard 
                                Name='HelpDesk'
                                Logo='https://w7.pngwing.com/pngs/1018/580/png-transparent-help-desk-technical-support-information-technology-helpline-business-text-service-people.png'
                                Desc="Submit or solve tickets."
                                Link='https://helpdesk.heathus.com/'
                            />

                            <AppCard 
                                Name='Engage'
                                Logo='https://play-lh.googleusercontent.com/0Hrdb0RJq8IEGQPvHk_HZL9JIJa0o7WiBNgNmHrHwLJOBNzVqVw3-O4l4SWv8g-bMw'
                                Desc="Social media site to engage with your fellow coworkers and activities within Heath."
                                Link='https://web.yammer.com/main/feed'
                            />

                            <AppCard 
                                Name='Office 365'
                                Logo='https://www.clipartmax.com/png/middle/117-1178605_office-365-grande-office-365-logo-2018.png'
                                Desc="An aggregation of all apps and services provided by Microsoft within Heath."
                                Link='https://www.office.com/'
                            />
                        </>
                    }
                   
                   {activepill == 'Communication' &&
                        <>
                            <AppCard 
                                Name='Outlook'
                                Logo='https://banner2.cleanpng.com/20180418/cfq/kisspng-microsoft-outlook-outlook-com-outlook-mobile-email-outlook-5ad785fe545f10.8090671015240739823456.jpg'
                                Desc="Provides emails, contacts, calendaring, and task management."
                                Link='https://outlook.office.com/mail/'
                            />

                            <AppCard 
                                Name='Teams'
                                Logo='https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg/2203px-Microsoft_Office_Teams_%282018%E2%80%93present%29.svg.png'
                                Desc="Messaging system with communication, meetings, file and app sharing"
                                Link='https://teams.microsoft.com/'
                            />

                            
                            <AppCard 
                                Name='Engage'
                                Logo='https://play-lh.googleusercontent.com/0Hrdb0RJq8IEGQPvHk_HZL9JIJa0o7WiBNgNmHrHwLJOBNzVqVw3-O4l4SWv8g-bMw'
                                Desc="Social media site to engage with your fellow coworkers and activities within Heath."
                                Link='https://web.yammer.com/main/feed'
                            />

                        </>
                    }

                    {activepill == 'Productivity' &&
                        <>
                        
                            <AppCard 
                                Name='Word'
                                Logo='https://logos-world.net/wp-content/uploads/2020/03/Microsoft-Word-Logo.png'
                                Desc="Create and edit documents containing text and images."
                                Link='https://www.office.com/launch/word'
                            />

                            <AppCard 
                                Name='Sharepoint'
                                Logo='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Microsoft_Office_SharePoint_%282019%E2%80%93present%29.svg/2097px-Microsoft_Office_SharePoint_%282019%E2%80%93present%29.svg.png'
                                Desc="Share and manage content, knowledge, and applications to empower teamwork."
                                Link='https://www.microsoft.com/en/microsoft-365/sharepoint/collaboration'
                            />


                            <AppCard 
                                Name='HelpDesk'
                                Logo='https://w7.pngwing.com/pngs/1018/580/png-transparent-help-desk-technical-support-information-technology-helpline-business-text-service-people.png'
                                Desc="Submit or solve tickets."
                                Link='https://helpdesk.heathus.com/'
                            />

                            <AppCard 
                                Name='Engage'
                                Logo='https://play-lh.googleusercontent.com/0Hrdb0RJq8IEGQPvHk_HZL9JIJa0o7WiBNgNmHrHwLJOBNzVqVw3-O4l4SWv8g-bMw'
                                Desc="Social media site to engage with your fellow coworkers and activities within Heath."
                                Link='https://web.yammer.com/main/feed'
                            />

                            <AppCard 
                                Name='Office 365'
                                Logo='https://www.clipartmax.com/png/middle/117-1178605_office-365-grande-office-365-logo-2018.png'
                                Desc="An aggregation of all apps and services provided by Microsoft within Heath."
                                Link='https://www.office.com/'
                            />
                        </>
                    }
                    
                </div>
           
            </div>
           
        </div>
    )
}

export default Apps