import React, {useMemo, useEffect, useState, useRef} from 'react';
import { RouteHelper } from '../components/routehelper';
import { Pills, PillsTopMenu } from '../components/pills';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

let Directory = (props) => {
    const [error, isError] = useState(false)
    const [search, isSearching] = useState(false)
    const [data, isData] = useState([])
    const [assistantData, isAssistantData] = useState([])
    const [searchData, isSearchData] = useState([])
    const [notif, isNotif] = useState('hide')
    const [showmenu, isShowMenu] = useState(false)
    const myElementRef = useRef(null);
    const [assistant, isAssistant] = useState(false);
    const [activepill,isActivePill] = useState('Administrative')
    const [lookfor,isLookingFor] = useState(0)
    const [profile,isProfile] = useState({
        FirstName: 'N/A',
        LastName: 'N/A',
        PhoneNumber: 'N/A',
        JobTitle: 'N/A',
        Location: 'N/A',
        Department: 'N/A',
        ReportsTo: 'N/A',
        Email:'N/A'
    })
    
    

    const getDirectory = async (job) => {

        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        fetch(`https://greet-server.azurewebsites.net/api/directory/getemployeebyjob?job=${job}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log(JSON.parse(result))
            
            isData(JSON.parse(result))
        })
        .catch(error => {
            console.log('error', error)
            isError(true)
        });
    };

    const InfoCard = data.map((data,index) => 
        <div className='customCol col-xl-2 col-lg-3 col-md-4 col-6 text-center mb-3'>
            <div
                className='contactCard h-100 animate__animated animate__fadeIn'
                
                index={index}
                key={index}
                onClick={() => {
                    isProfile({
                        FirstName: data.FirstName,
                        LastName: data.LastName,
                        PhoneNumber: data.PhoneNumber,
                        JobTitle: data.JobTitle?.split(',')[0]?.trim(),
                        Location: data?.LocationDescription,
                        Department: data?.JobTitle?.split(',')[1]?.trim(),
                        ReportsTo: data.ReportsToName,
                        Email:data.CompanyEmail
                    })
                   
                }}
                data-bs-toggle="modal" data-bs-target="#profileModal"
            >
                <img 
                    src='https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg' 
                    width={75}
                    style={{borderRadius:100,marginBottom:15,marginTop:15}}
                />
                <h6 className='mb-0 col text-center ellipsedTitle'>{data.FirstName + ' ' + data.LastName}</h6>
                <small 
                    className='mb-0 col text-center ellipsed'
                    style={{color:'rgb(92, 107, 192)'}}
                >{data.JobTitle}</small>

                <div className='row' style={{marginTop:10,backgroundColor:'rgb(196 213 233 / 40%)',marginLeft:10,marginRight:10,borderRadius:100,padding:1}}>
                    {data.PhoneNumber &&
                        <span className="material-icons col" style={{color:'#5C6BC0'}}>call</span>
                    }
                    {data.CompanyEmail &&
                        <span className="material-icons col" style={{color:'#5C6BC0'}}>email</span>
                    }
                    {!data.CompanyEmail && !data.PhoneNumber &&
                        <p className="mb-0" style={{color:'#5C6BC0'}}>No contact info</p>
                    }
                </div>
            </div>
           
          
            
        </div>
    )

  
    const SearchCard = searchData.map((data,index) => 
        <div className='customCol col-xl-2 col-lg-3 col-md-4 col-6 text-center mb-3'>
            <div
                className='contactCard h-100 animate__animated animate__fadeIn'
                
                index={index}
                key={index}
                onClick={() => {
                    isProfile({
                        FirstName: data.FirstName,
                        LastName: data.LastName,
                        PhoneNumber: data.PhoneNumber,
                        JobTitle: data.JobTitle?.split(',')[0]?.trim(),
                        Location: data?.LocationDescription,
                        Department: data?.JobTitle?.split(',')[1]?.trim(),
                        ReportsTo: data.ReportsToName,
                        Email:data.CompanyEmail
                    })
                
                }}
                data-bs-toggle="modal" data-bs-target="#profileModal"
            >
                <img 
                    src='https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg' 
                    width={75}
                    style={{borderRadius:100,marginBottom:15,marginTop:15}}
                />
                <h6 className='mb-0 col text-center ellipsedTitle'>{data.FirstName + ' ' + data.LastName}</h6>
                <small 
                    className='mb-0 col text-center ellipsed'
                    style={{color:'rgb(92, 107, 192)'}}
                >{data.JobTitle}</small>

                <div className='row' style={{marginTop:10,backgroundColor:'rgb(196 213 233 / 40%)',marginLeft:10,marginRight:10,borderRadius:100,padding:1}}>
                    {data.PhoneNumber &&
                        <span className="material-icons col" style={{color:'#5C6BC0'}}>call</span>
                    }
                    {data.CompanyEmail &&
                        <span className="material-icons col" style={{color:'#5C6BC0'}}>email</span>
                    }
                    {!data.CompanyEmail && !data.PhoneNumber &&
                        <p className="mb-0" style={{color:'#5C6BC0'}}>No contact info</p>
                    }
                </div>
            </div>
        
        
            
        </div>
    )


    let SelectBox = (props) => (
        <div 
            className='d-flex col-4 selectBox' 
            style={{
                border:'3px solid whitesmoke',
                padding:10,
                height:'100%',
                marginRight:10,
                marginBottom:10,
                minHeight:75,
                justifyContent:'center',
                alignItems:'center',
            }}
            onClick={props.onClick}
            data-bs-target="#myCarousel" data-bs-slide="next"
        >
            <p className='mb-0'>{props.Text}</p>
            
        </div>
    )

 
    useEffect(() => {
        getDirectory(activepill)
        
    },[activepill])


   

    return (
        <div 
            className='w-100' 
            onScroll={(event) => {
                let scrollPos = event.currentTarget.scrollTop
                console.log(event.currentTarget.scrollTop)
                if(scrollPos > 475){
                    isShowMenu(true)
                }
                else{
                    isShowMenu(false)
                }
            }} 
            style={{overflow:'hidden',height:'100vh'}}
        >
            {showmenu &&
                <div className='animate__animated animate__fadeInDown' style={{
                    backgroundColor:'#d1c4e9e0',
                    position:'fixed',
                    top:0,
                    zIndex:9999,
                    padding:10,
                    paddingBottom:0,
                    overflowX:'auto',
                    width:'100%',
                    // justifyContent:'center',
                    // alignItems:'center'
                }}>
                    <div 
                        className='d-flex text-center'
                        style={{
                            justifyContent:'center',
                            alignItems:'center'
                        }}
                    >
                        <PillsTopMenu 
                            Active={activepill} 
                            onClick={() => isActivePill('Administrative')}
                            Text='Administrative'
                        />
                        <PillsTopMenu 
                            Active={activepill} 
                            onClick={() => isActivePill('Customer Service')}
                            Text='Customer Service'
                        />
                        <PillsTopMenu 
                            Active={activepill} 
                            onClick={() => isActivePill('Engineering')}
                            Text='Engineering'
                        />
                        <PillsTopMenu 
                            Active={activepill} 
                            onClick={() => isActivePill('Factory Service')}
                            Text='Factory Service'
                        />
                        <PillsTopMenu 
                            Active={activepill} 
                            onClick={() => isActivePill('Human Resources')}
                            Text='Human Resources'
                        />
                        {/* <PillsTopMenu 
                            Active={activepill} 
                            onClick={() => isActivePill('')}
                            Text='HR - Safety'
                        />
                        <PillsTopMenu 
                            Active={activepill} 
                            onClick={() => isActivePill('HR')}
                            Text='HR - Legal/Risk'
                        /> */}
                        <PillsTopMenu 
                            Active={activepill} 
                            onClick={() => isActivePill('Marketing')}
                            Text='Marketing'
                        />
                        <PillsTopMenu 
                            Active={activepill} 
                            onClick={() => isActivePill('Manufacturing')}
                            Text='Manufacturing'
                        />
                        <PillsTopMenu 
                            Active={activepill} 
                            onClick={() => isActivePill('Sales')}
                            Text='Sales'
                        /> 
                        <PillsTopMenu 
                            Active={activepill} 
                            onClick={() => isActivePill('SBU')}
                            Text='SBU'
                        />
                    </div>

                    {/* <div className='w-100 d-flex' style={{justifyContent:'center',alignItems:'center'}}>
                        <input 
                            placeholder='Search for someone...' 
                            className='searchCustom text-center mb-3 w-25 d-flex'
                            onChange={(e) => {
                                let text = e.target.value
                                console.log(text)
                                

                                // console.log(data.filter(mydata => mydata.FirstName.includes(text)))
                                
                                
                                if(text.length > 0){
                                    isSearching(true)
                                    isSearchData(data.filter(mydata => mydata.FirstName.toLowerCase().includes(text) || mydata.LastName.toLowerCase().includes(text)))
                                }
                                else{
                                    isSearching(false)
                                }
                                
                                
                            }}
                        />
                    </div> */}
                </div>
            }

            <br/>

            <div style={{width:'100%',height:'100%',margin:'auto'}}>
                
                <div className='midContainer'>
                    <RouteHelper 
                        onClick={() => {
                            isAssistant(true)
                            isLookingFor(1)
                            console.log(assistant)
                        }} 
                        BackBtnOnClick={() => {
                            isAssistant(false)
                            
                        
                        }}
                        Assistant={assistant}
                        
                        Carousel1={
                            <>
                                <SelectBox 
                                    onClick={() => {
                                        isActivePill('sales')
                                        isLookingFor(2)
                                    }}
                                    Text='Sales / Technical Support / ESG'
                                />
                                <SelectBox 
                                    onClick={() => console.log('hello')}
                                    Text='Parts / Products / Order Processing'
                                />
                                <SelectBox 
                                    onClick={() => console.log('hello')}
                                    Text='Product Repairs'
                                />
                                <SelectBox 
                                    onClick={() => console.log('hello')}
                                    Text='Division Offices'
                                />
                                <SelectBox 
                                    onClick={() => console.log('hello')}
                                    Text='Corporate Departments / Emp Assistance'
                                />
                                <SelectBox 
                                    onClick={() => console.log('hello')}
                                    Text='All Other Inquiries & Operator'
                                />
                            </>  
                        }

                        Carousel2={InfoCard}
                    />
                </div>
                    
                <br/>

                
                {!assistant &&
                    <>
                        <div className='d-flex animate__animated animate__fadeIn' 
                            style={{
                                // marginLeft:15,
                                // marginRight:'auto',
                                marginTop:'1%',
                                justifyContent:'center',
                                alignItems:'center',
                                width:'100%',
                                flexWrap:'wrap',
                                overflowX:'auto',
                                marginBottom:'1%',
                            }}
                        >
                            <Pills 
                                Active={activepill} 
                                onClick={() => isActivePill('Administrative')}
                                Text='Administrative'
                            />
                            <Pills 
                                Active={activepill} 
                                onClick={() => isActivePill('Customer Service')}
                                Text='Customer Service'
                            />
                            <Pills 
                                Active={activepill} 
                                onClick={() => isActivePill('Engineering')}
                                Text='Engineering'
                            />
                            <Pills 
                                Active={activepill} 
                                onClick={() => isActivePill('Factory Service')}
                                Text='Factory Service'
                            />
                            <Pills 
                                Active={activepill} 
                                onClick={() => isActivePill('Human Resources')}
                                Text='Human Resources'
                            />
                            
                            <Pills 
                                Active={activepill} 
                                onClick={() => isActivePill('Marketing')}
                                Text='Marketing'
                            />
                            <Pills 
                                Active={activepill} 
                                onClick={() => isActivePill('Manufacturing')}
                                Text='Manufacturing'
                            />
                            <Pills 
                                Active={activepill} 
                                onClick={() => isActivePill('Sales')}
                                Text='Sales'
                            /> 
                            <Pills 
                                Active={activepill} 
                                onClick={() => isActivePill('SBU')}
                                Text='SBU'
                            />

                        
                        </div>

                        <div className='w-100 mb-3' style={{position:'relative'}}>
                            <input 
                                placeholder='Search for someone...' 
                                className='searchCustom text-center'
                                onChange={(e) => {
                                    let text = e.target.value
                                    console.log(text)
                                    

                                    // console.log(data.filter(mydata => mydata.FirstName.includes(text)))
                                    
                                
                                    if(text.length > 0){
                                        isSearching(true)
                                        isSearchData(data.filter(mydata => mydata.FirstName.toLowerCase().includes(text) || mydata.LastName.toLowerCase().includes(text)))
                                    }
                                    else{
                                        isSearching(false)
                                    }
                                    
                                
                                }}
                            />
                            <span class="material-icons search-icon">search</span>
                        </div>
                    </>        
                    
                }
                    
                
            
                {!assistant &&
                    <div 
                        id='test'
                        ref={myElementRef}
                        className='animate__animated animate__fadeInUp'
                        style={{
                            width:'100%',
                            height:'100%',
                            margin:'auto',
                            backgroundColor:'#ffffffe0',
                            borderRadius:24,
                            boxShadow: '0px 4px 12px -37px rgba(0,0,0,0.1)',
                            padding:15,
                            overflow:'auto',
                        }}
                    >
                        {!error?
                            (
                                <>
                                    {!search?
                                        (
                                            <div className='row w-100'>
                                                {data.length > 0? 
                                                    (InfoCard)
                                                    :
                                                    (<p style={{marginTop:'10%'}}>There seems to be no data available for this query.</p>)
                                                }
                                                
                                            </div>
                                        )
                                        :
                                        (
                                            <div className='row w-100'>
                                                {SearchCard}
                                            </div>
                                        )
                            
                                    }
                                    
                                    <div style={{width:'100%',height:'70%'}} />
                                </>
                            )
                            :
                            ( 
                                <div className='w-100 row '>
                                    <Player
                                        autoplay
                                        loop
                                        src="https://lottie.host/65f90351-70cd-40ae-bb47-03e08e71b2b7/znCNYJb3En.json"
                                        style={{ height: '40%', width: '50%',justifyContent:'center', alignItems:'center' }}
                                    >
                                        <h4 style={{width:'75%',margin:'auto'}}>An error occured while trying to the reach the servers. If this error persist after refresh, please notify us <a href='mailto:khadim.mbow@heathus.com'> here</a></h4>
                                    </Player>
                                
                                </div>
                            )
                        }
                       
                    </div>
                }
                
            </div>

            <div class="modal" id="profileModal" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content custom-modal">
                        <span 
                            className="material-icons headerIcons"
                            data-bs-dismiss="modal"
                            style={{position:'absolute',right:'5%'}}
                        >close</span>
                        <div className='row'>
                            <div className='col text-center' style={{margin:'auto'}} >
                                <span 
                                    className="material-icons headerIcons"
                                    data-bs-target="#liveToast"
                                    data-bs-toggle="toast"
                                    onClick={() => {
                                        navigator.clipboard.writeText(  `
                                            Name : ${profile.FirstName + ' ' + profile.LastName}
                                            Job Title : ${profile.JobTitle? profile.JobTitle : 'N/A'}
                                            Email : ${profile.Email? profile.Email : 'N/A'}
                                            Phone : ${profile.PhoneNumber? profile.PhoneNumber : 'N/A'}
                                            Location: ${profile.Location? profile.Location : 'N/A'},
                                            Department: ${profile.Department? profile.Department : 'N/A'},
                                            ReportsTo: ${profile.ReportsTo? profile.ReportsTo : 'N/A'},
                                        `);
                                        isNotif('show')
                                        setTimeout(() => {
                                            isNotif('hide')
                                        }, 5000);
                                    }}
                                >content_copy</span>

                                <div class="toast-container position-fixed top-0 end-0 p-3">
                                    <div id="liveToast" class={`toast fade ${notif}`} role="alert" aria-live="assertive" aria-atomic="true">
                                        <div class="toast-body">
                                            Profile card copied to clipboard.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col text-center'>
                                <img 
                                    src='https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg' 
                                    width={100}
                                    style={{borderRadius:100,marginBottom:15,marginTop:15}}
                                />
                                <h6>{profile.FirstName + ' ' + profile.LastName}</h6>
                                <small>{profile.JobTitle? profile.JobTitle : 'N/A'}</small>
                            </div>

                            <div className='col text-center' style={{margin:'auto'}}>
                                <span 
                                    className="material-icons headerIcons"
                                    onClick={() => {
                                        if (navigator.share) {
                                            navigator.share({
                                            title: `${profile.FirstName + ' ' + profile.LastName + ' profile card'}`,
                                            text: 
                                                `
                                                Name : ${profile.FirstName + ' ' + profile.LastName}
                                                Job Title : ${profile.JobTitle? profile.JobTitle : 'N/A'}
                                                Email : ${profile.Email? profile.Email : 'N/A'}
                                                Phone : ${profile.PhoneNumber? profile.PhoneNumber : 'N/A'}
                                                Location: ${profile.Location? profile.Location : 'N/A'},
                                                Department: ${profile.Department? profile.Department : 'N/A'},
                                                ReportsTo: ${profile.ReportsTo? profile.ReportsTo : 'N/A'},
                                                `
                                            })
                                        } 
                                        else {
                                            alert("This device can't share!")
                                        }
                                    }}
                                >share</span>
                            </div>
                        </div>

                        <br/>

                        <div className='row'>
                            <div className='col-12 col-md-6 text-center mb-2'>
                                <div className='customBox row' 
                                    onClick={() => {
                                        window.open('mailto:' + profile.Email,'_blank')
                                    }}
                                >
                                    <div className='col-2' style={{margin:'auto'}}>
                                        <span className="material-icons" style={{color:'gray',fontSize:20}}>email</span>
                                    </div>
                                    
                                    <div className='col-10' style={{textAlign:'left'}}>
                                        <small style={{color:'gray',fontSize:13}}>Email</small>
                                        <p className='mb-0'>{profile.Email? profile.Email : 'N/A'}</p>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className='col-12 col-md-6 text-center mb-2'>
                                <div 
                                    className='customBox row'
                                    onClick={() => {
                                        profile.PhoneNumber? window.open('tel:' + profile.PhoneNumber,'_blank') : alert("There is no number associated with this profile.")
                                    }}
                                >
                                    <div className='col-2' style={{margin:'auto'}}>
                                        <span className="material-icons" style={{color:'gray',fontSize:20}}>call</span>
                                    </div>
                                    
                                    <div 
                                    className='col-10' style={{textAlign:'left'}}>
                                        <small style={{color:'gray',fontSize:13}}>Phone</small>
                                        <p className='mb-0'>{profile.PhoneNumber? profile.PhoneNumber : 'N/A'}</p>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className='col-12 col-md-6 text-center mb-2'>
                                <div 
                                    className='customBox row'
                                    onClick={() => {
                                        window.open('https://teams.microsoft.com/','_blank')
                                    }}
                                >
                                    <div className='col-2' style={{margin:'auto'}}>
                                        <span className="material-icons" style={{color:'gray',fontSize:20}}>chat</span>
                                    </div>
                                    
                                    <div className='col-10' style={{textAlign:'left'}}>
                                        <small style={{color:'gray',fontSize:13}}>Teams</small>
                                        <p className='mb-0'>{profile.Email? profile.Email : 'N/A'}</p>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className='col-12 col-md-6 text-center mb-2'>
                                <div className='customBox row'>
                                    <div className='col-2' style={{margin:'auto'}}>
                                        <span className="material-icons" style={{color:'gray',fontSize:20}}>location_on</span>
                                    </div>
                                    
                                    <div className='col-10' style={{textAlign:'left'}}>
                                        <small style={{color:'gray',fontSize:13}}>Location</small>
                                        <p className='mb-0'>{profile.Location? profile.Location : 'N/A'}</p>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className='col-12 col-md-6 text-center mb-2'>
                                <div className='customBox row'>
                                    <div className='col-2' style={{margin:'auto'}}>
                                        <span className="material-icons" style={{color:'gray',fontSize:20}}>email</span>
                                    </div>
                                    
                                    <div className='col-10' style={{textAlign:'left'}}>
                                        <small style={{color:'gray',fontSize:13}}>Department</small>
                                        <p className='mb-0'>{profile.Department? profile.Department: 'N/A'}</p>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className='col-12 col-md-6 text-center mb-2'>
                                <div className='customBox row'>
                                    <div className='col-2' style={{margin:'auto'}}>
                                        <span className="material-icons" style={{color:'gray',fontSize:20}}>person</span>
                                    </div>
                                    
                                    <div className='col-10' style={{textAlign:'left'}}>
                                        <small style={{color:'gray',fontSize:13}}>Reports To</small>
                                        <p className='mb-0'>{profile.ReportsTo? profile.ReportsTo : 'N/A'}</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

           
           
        </div>
    )
}

export default Directory